<template>
  <div class="wrap">
    <!-- 账户信息 -->
    <div class="account-box">
      <!--<router-link to='/testDemo'><el-button>修改</el-button></router-link>-->
      <Title name="账户信息" operate="修改" height="26px" lineHeight="26px"></Title>
      <div class="content">
        <div class="photo" :style="{backgroundImage: 'url(' + ruleForm.avatar + ')'}">
          <div class="upload-btn">
            <i class="icon el-icon-upload2"></i>
            <!-- 上传头像 -->
            <el-upload class="upload" :action="updateUrl" list-type="picture-card" :show-file-list="false" :before-upload="beforeLicenseUpload" :on-success="handleUploadSuccess"> </el-upload>
            <!--            <el-image :src="this.ruleForm.avatar"></el-image>-->
          </div>
        </div>
        <div class="info">
          <p v-if="!isEditNmae" class="name" style="display: flex; align-items: center" @click="showIsEditNmaeInput">
            <span>用户名：{{ ruleForm.users }}</span> <i class="el-icon-edit"></i>
          </p>
          <p v-else class="name" style="display: flex; align-items: center">
            <span>用户名：</span>
            <input class="inputname" style="width: 180px; padding: 2px 5px" ref="inputName" autofocus="autofocus" v-model="ruleForm.users" @blur="editName" />
          </p>

          <div class="account">
            <p>用户类型：{{ ruleForm.accountType == 'BUY' ? '买家' : '供应商' }}</p>
          </div>
          <div class="account">
            <p>账<span v-html="'\u00a0\u00a0'" style="margin-left: 17px"></span>号：{{ ruleForm.loginName }}</p>
            <span @click="toEditPassword">修改账户密码</span>
          </div>
          <div class="account">
            <p>手<span v-html="'\u00a0\u00a0'" style="margin-left: 17px"></span>机：{{ ruleForm.tel }}</p>
            <span @click="toBindingPhone">重置绑定手机</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业信息 -->
    <div class="enterprise-box">
      <Title name="企业信息" operate="修改">
        <p v-if="ruleForm2.corName != '' && ruleForm2.province == '1'">
          <router-link :to="{path: '/User/AttestInfo', query: {updateType: 'client'}}">
            <el-button style="padding: 6px 10px; font-size: 12px">修改</el-button>
          </router-link>
        </p>
      </Title>
      <div class="content">
        <p><span>公司名称：</span>{{ ruleForm2.clientName | hideTextInfo(3, 4) }}</p>
        <p>
          <span>公司营业执照：</span><a :href="ruleForm2.businessLicenseImages" target="_blank"><span class="see">查看</span></a>
        </p>
        <p>
          <span>纳税人资格证书：</span><a :href="ruleForm2.taxpayerCertificate" target="_blank"><span class="see">查看</span></a>
        </p>
        <p><span>类型：</span>{{ ruleForm2.enterpiseType | enterpiseTypeFilter }}</p>
        <p><span>公司地址：</span>{{ ruleForm2.offAddr | hideTextInfo(3, 4) }}</p>
        <p><span>注册资本：</span>{{ ruleForm2.regCapital | hideTextInfo(1, 0) }}</p>
        <p><span>成立时间：</span>{{ ruleForm2.regDay | hideTextInfo(4, 0) }}</p>
        <p><span>经营范围：</span>{{ ruleForm2.busScope | hideTextInfo(0, 0) }}</p>
      </div>
    </div>

    <!-- 法人信息 -->
    <div class="enterprise-box">
      <Title name="法人信息" operate="修改">
        <p v-if="ruleForm2.corName != '' && ruleForm2.province == '1'">
          <router-link :to="{path: '/User/AttestInfo', query: {updateType: 'corUser'}}">
            <el-button style="padding: 6px 10px; font-size: 12px">修改</el-button>
          </router-link>
        </p>
      </Title>
      <div class="content">
        <p><span>法人姓名：</span>{{ ruleForm2.corName | hideTextInfo(1, 0) }}</p>
        <p><span>法人身份证号码：</span>{{ ruleForm2.idCardNo | hideTextInfo(1, 1) }}</p>
        <p><span>证件签发机关：</span>{{ ruleForm2.organization | hideTextInfo(1, 2) }}</p>
        <p><span>证件签发日期：</span>{{ ruleForm2.expiryStartDate | hideTextInfo(4, 0) }}</p>
        <p><span>证件失效日期：</span>{{ ruleForm2.expiryEndtDate | hideTextInfo(4, 0) }}</p>
      </div>
    </div>

    <!-- 收付款账号 -->
    <div class="enterprise-box">
      <Title name="收付款账号" operate="修改">
        <p v-if="ruleForm2.province == '1'">
          <el-button style="padding: 6px 10px; font-size: 12px" @click="showDialogFormVisible">添加</el-button>
        </p>
      </Title>
      <div class="content" v-if="ruleFormStatus3">
        <el-table :data="ruleFormData3" style="width: 100%">
          <el-table-column prop="accountName" label="账户名" width="180"> </el-table-column>
          <el-table-column prop="accountNo" label="银行账号" width="180"> </el-table-column>
          <el-table-column prop="bankInfoId" label="开户行"> </el-table-column>
          <el-table-column prop="accountBank" label="开户网点"> </el-table-column>
          <el-table-column prop="bankRadio" label="默认账户">
            <template slot-scope="scope">
              {{ scope.row.isDefault == '0' ? '否' : '是' }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="showChange(scope.row, scope.$index)" type="text" size="small">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <p><span>账户名：</span>{{ ruleFormData3.accountName | hideTextInfo(1, 0) }}</p>
        <p><span>银行账号：</span>{{ ruleFormData3.accountNo | hideTextInfo(1, 1) }}</p>
        <p><span>开户行：</span>{{ ruleFormData3.bankInfoId | hideTextInfo(1, 2) }}</p>
        <p><span>开户网点：</span>{{ ruleFormData3.accountBank | hideTextInfo(1, 2) }}</p> -->
      </div>
    </div>

    <div class="enterprise-box">
      <Title name="开票账号" operate="修改">
        <p v-if="ruleForm2.province == '1'">
          <el-button style="padding: 6px 10px; font-size: 12px" @click="showDialogFormVisible2">{{ ruleFormStatus4 ? '修改' : '添加' }}</el-button>
        </p>
      </Title>

      <div class="content" v-if="ruleFormStatus4">
        <!--        <p><span>账户名：</span>{{ruleFormData4.accountName | hideTextInfo(1, 0)}}</p>-->
        <p><span>纳税号：</span>{{ ruleFormData4.payTaxesNo | hideTextInfo(1, 2) }}</p>
        <p><span>银行账号：</span>{{ ruleFormData4.accountNo | hideTextInfo(1, 1) }}</p>
        <p><span>开户行：</span>{{ ruleFormData4.bankInfoId | hideTextInfo(1, 2) }}</p>
        <p><span>开户网点：</span>{{ ruleFormData4.accountBank | hideTextInfo(1, 2) }}</p>
      </div>
    </div>

    <el-dialog :title="blankStaus === 'edit' ? '修改收付款账号' : '新增收付款账号'" :visible.sync="dialogFormVisible" width="420px">
      <el-form inline label-width="100px" :model="ruleForm3" :rules="rules3" ref="ruleForm3">
        <el-form-item label="开户行" prop="bankInfoId">
          <el-select v-model="ruleForm3.bankInfoId" placeholder="请选择" style="width: 206.4px">
            <el-option v-for="(item, index) in bankInfoList" :key="index" :label="item.bankName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="开户网点" prop="accountBank">
          <el-input v-model="ruleForm3.accountBank" :maxlength="100" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="账户名" prop="accountName">
          <el-input v-model="ruleForm3.accountName" :maxlength="20" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="accountNo">
          <el-input v-model="ruleForm3.accountNo" :maxlength="30" autocomplete="off" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"></el-input>
        </el-form-item>
        <el-form-item v-if="blankStaus === 'edit'" label="默认账户" prop="accountNo">
          <el-radio v-model="ruleForm3.bankRadio" label="1">是</el-radio>
          <el-radio v-model="ruleForm3.bankRadio" label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRuleForm3('ruleForm3')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="开票账号" :visible.sync="dialogFormVisible2" width="420px">
      <el-form inline label-width="100px" :model="ruleForm4" :rules="rules4" ref="ruleForm4">
        <el-form-item label="开户行" prop="bankInfoId">
          <el-select v-model="ruleForm4.bankInfoId" placeholder="请选择" style="width: 206.4px">
            <el-option v-for="(item, index) in bankInfoList" :key="index" :label="item.bankName" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="开户网点" prop="accountBank">
          <el-input v-model="ruleForm4.accountBank" :maxlength="100" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="纳税号" prop="payTaxesNo">
          <el-input v-model="ruleForm4.payTaxesNo" :maxlength="20" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="accountNo">
          <el-input v-model="ruleForm4.accountNo" :maxlength="30" autocomplete="off" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submitRuleForm4('ruleForm4')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Title from '../../component/Title';
import {updatePayInfo, insertPayInfo, findByClientInfoId, updateUserNameOrPhotoUrl, selectByPrimaryKey, getCollectPayInfo, addCollectPayInfo, bankInfoAll} from '../../api';
import {hideTextInfo} from '@/utils/util';
import {API_PREFIX} from '@/api/config';
const enterpiseType = {
  1: '股份有限公司',
  2: '有限责任公司',
  3: '合伙企业',
  4: '个人独资企业',
  5: '其他',
  6: '个体工商户'
};

export default {
  created() {
    // alert( localStorage.getItem("userId"))
    this.bankInfoAll();
    this.selectByPrimaryKey();
    this.findByClientInfoId();
    this.getCollectPayInfo();
  },
  data() {
    return {
      banlkIndex: 0,
      updateUrl: location.origin + API_PREFIX + '/wx/storage/upload',
      bankInfoList: [],
      ruleForm: {
        users: '', //使用人
        loginName: '', //账号
        tel: '', //手机号
        accountType: '', //类型
        avatar: require('@/assets/photo.png')
      },
      ruleForm2: {
        clientName: '', //公司名称
        offAddr: '', //公司地址
        busScope: '', //经营范围
        businessLicenseImages: undefined, //公司营业执照
        regCapital: '', //注册资本
        enterpiseType: '', // 类型
        regDay: '', //成立时间

        corName: '', //法人姓名
        idCardNo: '', //法人身份证号码
        organization: '', //证件签发机关
        expiryStartDate: '', //证件签发日期
        expiryEndtDate: '', //证件失效日期
        province: '' //用于判断是否是父账号
      },
      ruleFormData3: {
        accountName: '',
        bankInfoId: '',
        accountNo: '',
        accountBank: '',
        bankRadio: '1'
      },
      ruleFormData4: {
        accountName: '',
        bankInfoId: '',
        accountNo: '',
        accountBank: '',
        payTaxesNo: ''
      },
      blankStaus: '',
      ruleForm3: {
        accountName: '',
        accountNo: '',
        accountBank: '',
        bankInfoId: '',
        bankRadio: '1'
      },
      ruleForm4: {
        accountNo: '',
        accountBank: '',
        payTaxesNo: ''
      },

      rules3: {
        accountName: [{required: true, message: '请输入账户名', trigger: 'blur'}],
        bankInfoId: [{required: true, message: '请选择开户行'}],
        accountNo: [{required: true, message: '请输入银行账号'}],
        accountBank: [{required: true, message: '请输入开户网点', trigger: 'blur'}],
        bankRadio: [{required: true, message: '请选择是否默认', trigger: 'blur'}]
      },
      rules4: {
        accountNo: [{required: true, message: '请输入银行账号'}],
        bankInfoId: [{required: true, message: '请选择开户行'}],
        accountBank: [{required: true, message: '请输入开户网点', trigger: 'blur'}],
        payTaxesNo: [{required: true, message: '请输入纳税号', trigger: 'blur'}]
      },
      isEditNmae: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      ruleFormStatus3: false,
      ruleFormStatus4: false
    };
  },
  computed: {},
  filters: {
    hideTextInfo,
    enterpiseTypeFilter(status) {
      return enterpiseType[status];
    }
  },
  methods: {
    getBankId(item) {
      let myArr = this.bankInfoList.filter(ele => {
        return ele.bankName === item;
      });
      console.log(myArr[0]);
      return myArr[0].id;
    },
    showChange(data, index) {
      this.banlkIndex = index;
      this.dialogFormVisible = true;
      this.blankStaus = 'edit';
      this.dialogFormVisible = true;
      this.ruleForm3.bankInfoId = this.getBankId(data.bankInfoId);
      this.ruleForm3.accountBank = data.accountBank;
      this.ruleForm3.accountName = data.accountName;
      this.ruleForm3.accountNo = data.accountNo;
      this.ruleForm3.bankRadio = data.isDefault;
    },
    //加载用户信息
    selectByPrimaryKey() {
      selectByPrimaryKey({
        id: localStorage.getItem('userId')
      }).then(res => {
          if (res.errno == 0) {

              this.ruleForm = Object.assign(this.ruleForm, res.data.loginUser);
            this.ruleForm.avatar=res.data.byTClientInfoId.logo;
              if (this.ruleForm.avatar==null || this.ruleForm.avatar==''){
                this.ruleForm.avatar=require('@/assets/photo.png');
          }
        } else {
          this.$notify.error({
            title: '失败',
            message: res.errmsg
          });
        }
      });
    },
    //加载公司信息
    findByClientInfoId() {
      findByClientInfoId({
        clientInfoId: localStorage.getItem('userId')
      }).then(res => {
        if (res.errno == 0) {
          if (res.data.apprvStatus == 'P') {
            this.ruleForm2 = res.data;
          }
          this.ruleForm2.province = res.data.province;
        } else {
          this.$notify.error({
            title: '失败',
            message: res.errmsg
          });
        }
      });
    },
    //加载收付款账号信息
    getCollectPayInfo() {
      console.log(22);
      getCollectPayInfo({
        clientInfoId: localStorage.getItem('userId')
      }).then(res => {
        if (res.errno == 0) {
          if (res.data) {
            if (res.data[0] != null) {
              this.ruleFormStatus3 = true;
              this.ruleFormData3 = res.data;
            }
            if (res.data[1] != null) {
              this.ruleFormStatus4 = true;
              this.ruleFormData4 = res.data[1];
            }
          } else {
            this.ruleFormStatus3 = false;
            this.ruleFormStatus4 = false;
          }
        }
      });
    },
    //加载合作银行
    bankInfoAll() {
      bankInfoAll().then(res => {
        this.bankInfoList = res.data;
      });
    },
    toEditPassword() {
      this.$router.push({
        path: '/User/CorporateInfo/EditPassword'
      });
    },
    updateUser() {
      updateUserNameOrPhotoUrl({
        users: this.ruleForm.users,
        avatar: this.ruleForm.avatar
      }).then(res => {
        localStorage.removeItem('users');
        localStorage.removeItem('avatar');
        localStorage.setItem('users', this.ruleForm.users);
        localStorage.setItem('avatar', this.ruleForm.avatar);
        this.$notify.success({
          title: '成功',
          message: '成功'
        });
      });
    },
    toBindingPhone() {
      this.$router.push({
        path: '/User/CorporateInfo/BindingPhone'
      });
    },
    showIsEditNmaeInput() {
      this.isEditNmae = true;
      this.$nextTick(() => {
        this.$refs.inputName.focus();
      });
    },
    editName() {
      this.isEditNmae = false;
      this.updateUser();
    },
    handleUploadSuccess(res) {
      this.ruleForm.avatar = res.data.url;
      this.updateUser();
    },
    // 显示添加收付款账号信息框
    showDialogFormVisible() {
      this.blankStaus = 'add';
      this.dialogFormVisible = true;
      this.ruleForm3.accountName = '';
      this.ruleForm3.accountNo = '';
      this.ruleForm3.accountBank = '';
      this.ruleForm3.bankInfoId = '';
      this.ruleForm3.bankRadio = '1';
    },
    //发票号
    showDialogFormVisible2() {
      this.dialogFormVisible2 = true;
      this.ruleForm4 = Object.assign({}, this.ruleFormData4);
    },
    // 提交收付款账号信息
    submitRuleForm3(formName) {
      if (this.blankStaus == 'edit') {
        let data = this.ruleFormData3[this.banlkIndex];
        let params = {
          accountName: this.ruleForm3.accountName,
          accountNo: this.ruleForm3.accountNo,
          bankInfoId: this.ruleForm3.bankInfoId,
          accountBank: this.ruleForm3.accountBank,
          isDefault: this.ruleForm3.bankRadio,
          id: data.id
        };
        this.$refs[formName].validate(async valid => {
          if (valid) {
            let res = await updatePayInfo(params);
            if (res.errno === 0) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              });
              this.dialogFormVisible = false;
              this.getCollectPayInfo();
            }
          }
        });
      } else {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let myObj = {
              ...this.ruleForm3,
              isDefault: '1'
            };
            insertPayInfo(myObj).then(res => {
              if (res.errno == 0) {
                this.ruleFormStatus3 = true;
                this.dialogFormVisible = false;
                this.$notify.success({
                  title: '成功',
                  message: '操作成功'
                });
              }
              this.getCollectPayInfo();
            });
          }
        });
      }
      this.blankStaus = '';
    },

    // 提交开票号码
    submitRuleForm4(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          addCollectPayInfo(_this.ruleForm4).then(res => {
            if (res.errno == 0) {
              _this.ruleFormData4 = Object.assign({}, res.data);
              _this.ruleFormStatus4 = true;
              _this.dialogFormVisible2 = false;
              _this.$notify.success({
                title: '成功',
                message: '操作成功'
              });
            }
          });
        }
      });
    },

    // 上传凭证之前
    beforeLicenseUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  },
  components: {
    Title
  }
};
</script>

<style lang="less" scoped>
@color1: #409eff;

.wrap {
  .inputname {
    &.el-input {
      /deep/ .el-input__inner {
        height: 22px;
        width: 180px;
      }
    }
  }

  .account-box {
    background: #fff;

    .content {
      padding: 30px;
      display: flex;
      align-items: center;

      .photo {
        width: 90px;
        height: 90px;
        background: #feda00;
        border-radius: 50%;
        overflow: hidden;
        background-size: 100% 100%;

        &:hover {
          .upload-btn {
            display: block;
            cursor: pointer;
          }
        }
        .upload-btn {
          position: relative;
          display: none;
          padding-top: 30px;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.4);
          text-align: center;

          .icon {
            font-size: 30px;
            color: #fff;
          }
          .upload {
            z-index: 1000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background: #000;
            opacity: 0;
          }
        }
      }
      .info {
        font-size: 14px;
        margin-left: 24px;

        .name {
          height: 22px;
          font-size: 14px;
          cursor: pointer;
        }
        .account {
          display: flex;
          margin-top: 8px;

          span {
            color: #9c9c9c;
            margin-left: 24px;
            cursor: pointer;
            &:hover {
              color: #409eff !important;
            }
          }
        }
      }
    }
  }

  .enterprise-box {
    background: #fff;
    margin-top: 10px;

    .content {
      padding: 20px 30px;
      display: flex;
      flex-wrap: wrap;

      p {
        width: 33%;
        margin: 14px 0;
        font-size: 12px;

        span {
          color: #9c9c9c;

          &.see {
            color: @color1;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
